import { ContextParams, EditorSDK } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import { getGlobalMembersAreaController } from '../wrappers/controllers';
import { shouldInstallMembersAreaV2 } from '../../utils/experiments';
import { isApplicationInstalled } from '../wrappers/application';
import { getIsADI } from './applicationState';
import { isAppSectionInstalled } from '../wrappers/tpa';
import { isApplicationReady as isV1Installed } from '../applicationState';

declare const self: {
  commonConfig: {
    brand: string;
  };
};

const getIsMembersAreaV1Installed = async (editorSDK: EditorSDK) => {
  return isAppSectionInstalled({
    editorSDK,
    appDefinitionId: MA_APP_IDS.MY_ACCOUNT.appDefinitionId,
    sectionId: MA_APP_IDS.MY_ACCOUNT.pageId,
  });
};

const isMembersAreaV1Context = async (editorSDK: EditorSDK, options?: ContextParams) => {
  return options?.silentInstallation || getIsADI() || (options?.firstInstall && getIsMembersAreaV1Installed(editorSDK));
};

export const getIsMembersAreaV2 = async (editorSDK: EditorSDK, options?: ContextParams) => {
  if (self?.commonConfig?.brand === 'studio') {
    const isV1 = await isV1Installed(editorSDK);
    return !isV1;
  }

  if (await isMembersAreaV1Context(editorSDK, options)) {
    return false;
  }

  if (options?.firstInstall) {
    return shouldInstallMembersAreaV2();
  }

  return isApplicationInstalled(editorSDK, MEMBERS_AREA_V2);
};

export const getIsMembersAreaSeoEnabled = async (editorSDK: EditorSDK) => {
  try {
    const controllerConfig = await getGlobalMembersAreaController(editorSDK);

    return !!controllerConfig?.isMembersAreaSeoEnabled;
  } catch (e) {
    return false;
  }
};
